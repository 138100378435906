import React, { Suspense, lazy } from "react";
import * as Sentry from "@sentry/browser";
import { css, select as $ } from "glamor";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { gql } from "@apollo/client";
import { Route, Switch, Redirect } from "react-router-dom";
import Moment from "moment-timezone";
import { Helmet } from "react-helmet";
import { updateIntercom } from "../helpers/functions";
import { colors } from "../helpers/styles";
import logoSoon from "../assets/images/soon-wordmark-black.svg";
import retry from "../helpers/retryLazyLoading";
import toast from "react-hot-toast";
import Loader from "./loader/Loader";
import { AnimatePresence, motion } from "framer-motion";
import ErrorBoundary from "./errorBoundary/ErrorBoundary";
import { ErrorFallback } from "./errorBoundary/ErrorFallback";
import Forecasts from "./forecasts/Forecasts";

const DashboardContainer = lazy(() =>
  retry(() => import("./dash/DashContainer"))
);
const PageNotFound = lazy(() =>
  retry(() => import("./authentication/PageNotFound"))
);
const BoardData = lazy(() => retry(() => import("./board/BoardContainer")));
const TeamContainer = lazy(() =>
  retry(() => import("./teamAccount/TeamContainer"))
);
const MembersContainer = lazy(() =>
  retry(() => import("./teamMembers/MembersContainer"))
);
const LeaveContainer = lazy(() =>
  retry(() => import("./leave/LeaveContainer"))
);

const UserPageContainer = lazy(() =>
  retry(() => import("./userPage/UserPageContainer"))
);
const VerifyEmail = lazy(() =>
  retry(() => import("./authentication/VerifyEmail"))
);
const Board = lazy(() => retry(() => import("./members/Board")));
const TeamProfileContainer = lazy(() =>
  retry(() => import("./members/teamProfile/TeamProfileContainer"))
);
const InboxContainer = lazy(() =>
  retry(() => import("./inbox/InboxContainer"))
);

let beat = css.keyframes({
  to: { transform: "scale(1.1)" },
});

class Router extends React.Component {
  state = {
    timezoneConflict: false,
  };
  componentDidMount() {
    if (
      this.props.timezone &&
      Moment.tz(this.props.timezone).format("ZZ") !==
        Moment.tz(Moment.tz.guess()).format("ZZ")
    ) {
      this.setState({ timezoneConflict: true });
    }

    if (this.props.user) {
      Sentry.setUser({
        id: this.props.user.id,
        email: this.props.user.email,
        username: this.props.user.name,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.user &&
      this.props.user.teams[0] &&
      this.props.user.teams[0].name
    ) {
      if (!this.props.data.loading && prevProps.data.loading) {
        const data = this.props.data.getTeamBillingPlan;
        if (data) {
          try {
            updateIntercom({
              data: {
                companies: [
                  {
                    id: this.props.teamId,
                    plan: data.plan,
                    provider: data.provider,
                  },
                ],
              },
            });
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
  }

  roleCheck = (props) => {
    const defaultScreenDate = this.props.user.teams[0].defaultScreenDate
      ? +Moment(this.props.user.teams[0].defaultScreenDate)
      : +new Date();

    function getToastText(string, type) {
      let message;
      if (type) {
        if (type === "MicrosoftOAuth") {
          message = "Microsoft";
        } else if (type === "GoogleOAuth") {
          message = "Google";
        }
        message = message + " account ";
      }
      if (string === "account_linked") {
        message = message + "connected";
      }
      if (string === "account_unlinked") {
        message = message + "disconnected";
      }
      if (
        string === "email_already_exists" ||
        string === "email_already_connected"
      ) {
        message = message + " not connected";
      }
      return message;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const message = urlParams.get("message");
    const error = urlParams.get("error");
    const ssoType = urlParams.get("ssoType");
    if (message) {
      toast.success(getToastText(message, ssoType));
    }
    if (error) {
      toast.error(getToastText(error, ssoType));
      if (error === "email_already_connected") {
        toast.error("This account is already connected");
      } else {
        toast.error("This email address already exists");
      }
    }

    if (this.props.userClass === "MEMBER") {
      return (
        <Redirect
          to={{
            pathname: "/myschedule/" + defaultScreenDate,
            hash: "#today",
          }}
        />
      );
    } else {
      return <Redirect to="/boards" />;
    }
  };

  renderBoards = (props) => {
    if (this.props.data.loading) {
      return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Helmet>
            <title>Boards | Soon</title>
          </Helmet>
          <DashboardContainer
            userId={this.props.user.id}
            userClass={this.props.userClass}
            {...props}
          />
        </ErrorBoundary>
      );
    }

    const owner = this.props.user.teams[0].owner;
    const isOwner = this.props.userClass === "SUPERADMIN";

    const data = this.props.data.getTeamBillingPlan;
    const billingPlan = data || {
      status: "loading",
    };

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Helmet>
          <title>Boards | Soon</title>
        </Helmet>
        <DashboardContainer
          userId={this.props.user.id}
          userClass={this.props.userClass}
          billingPlan={billingPlan}
          {...props}
        />
        <NoAccessCheck
          billingPlan={billingPlan}
          isOwner={isOwner}
          history={props.history}
          owner={owner}
        />
      </ErrorBoundary>
    );
  };

  renderSchedule = (props) => {
    const owner = this.props.user.teams[0].owner;
    const isOwner = this.props.userClass === "SUPERADMIN";

    const data = this.props.data.getTeamBillingPlan;
    const billingPlan = data || {
      plan: "",
      status: "loading",
      trialDaysLeft: "",
    };

    let view =
      window.innerHeight < 1400
        ? this.props.user.mobileSchedulePreference
        : "KANBAN";
    if (!view) {
      view = "KANBAN";
    }

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Helmet>
          <title>My Schedule | Soon</title>
        </Helmet>
        <InboxContainer
          render={(p, refetchInbox) => {
            return (
              <Board
                billingPlan={billingPlan}
                currentUser={this.props.user.id}
                teamId={this.props.user.teams[0].id}
                timezone={this.props.timezone}
                {...props}
                {...p}
                refetchInbox={refetchInbox}
                mobile={window.innerWidth < 481}
                view={view}
              />
            );
          }}
        />
        <NoAccessCheck
          billingPlan={billingPlan}
          isOwner={isOwner}
          history={props.history}
          owner={owner}
        />
      </ErrorBoundary>
    );
  };

  renderBoard = (props) => {
    const owner = this.props.user.teams[0].owner;
    const isOwner = this.props.userClass === "SUPERADMIN";

    const data = this.props.data.getTeamBillingPlan;
    const billingPlan = data || {
      plan: "",
      status: "loading",
      trialDaysLeft: "",
    };

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Helmet>
          <title>Board | Soon</title>
          <meta
            name="description"
            content="Access and manage your boards on Soon. Visualize schedules, activities, and workflows all in one place."
          />
        </Helmet>
        <InboxContainer
          render={(
            p,
            refetchInbox,
            handleEditMode,
            handleInbox,
            handleAutoFillMode
          ) => {
            return (
              <BoardData
                loading={this.props.data.loading}
                billingPlan={billingPlan}
                userId={this.props.user.id}
                teamId={this.props.user.teams[0].id}
                userClass={this.props.userClass}
                timezone={this.props.timezone}
                {...props}
                {...p}
                handleEditMode={handleEditMode}
                handleInbox={handleInbox}
                handleAutoFillMode={handleAutoFillMode}
                defaultScreenDate={
                  this.props.user.teams[0].defaultScreenDate
                    ? +Moment(this.props.user.teams[0].defaultScreenDate)
                    : +new Date()
                }
              />
            );
          }}
        />
        <NoAccessCheck
          billingPlan={billingPlan}
          isOwner={isOwner}
          history={props.history}
          owner={owner}
        />
      </ErrorBoundary>
    );
  };

  renderUsers = (props) => {
    const data = this.props.data.getTeamBillingPlan;
    const billingPlan = data || {
      plan: "",
      status: "loading",
      trialDaysLeft: "",
    };

    const member = !["ADMIN", "SUPERADMIN"].includes(this.props.userClass);
    if (member) {
      return this.roleCheck();
    }
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <InboxContainer
          render={(p) => {
            return (
              <MembersContainer
                billingPlan={billingPlan}
                userId={this.props.user.id}
                teamId={this.props.teamId}
                {...props}
                {...p}
              />
            );
          }}
        />
      </ErrorBoundary>
    );
  };

  renderUser = (props) => {
    return (
      <UserPageContainer
        teamId={this.props.user.teams[0].id}
        currentUserId={this.props.user.id}
        {...props}
      />
    );
  };

  renderLeave = (props) => {
    const owner = this.props.user.teams[0].owner;
    const isOwner = this.props.userClass === "SUPERADMIN";

    const data = this.props.data.getTeamBillingPlan;
    const billingPlan = data || {
      plan: "",
      status: "loading",
      trialDaysLeft: "",
    };

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Helmet>
          <title>Leave – Requests | Soon</title>
          <meta
            name="description"
            content="Request and manage leaves with Soon. Simplify the approval process and keep track of time off."
          />
        </Helmet>
        <InboxContainer
          render={(p) => {
            return (
              <LeaveContainer
                userId={this.props.user.id}
                teamClass={this.props.userClass}
                teamId={this.props.teamId}
                timezone={this.props.timezone}
                billingPlan={billingPlan}
                {...props}
                {...p}
              />
            );
          }}
        />
        <NoAccessCheck
          billingPlan={billingPlan}
          isOwner={isOwner}
          history={props.history}
          owner={owner}
        />
      </ErrorBoundary>
    );
  };

  renderAccount = (props) => {
    if (this.props.data.loading) {
      return <Loader />;
    }

    const data = this.props.data.getTeamBillingPlan;
    const billingPlan = data || {
      plan: "",
      status: "loading",
      trialDaysLeft: "",
      addOns: [],
    };

    const member = !["ADMIN", "SUPERADMIN"].includes(this.props.userClass);
    if (member) {
      return this.roleCheck();
    }

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <TeamContainer
          billingPlan={billingPlan}
          teamId={this.props.teamId}
          {...props}
        />
      </ErrorBoundary>
    );
  };

  renderTeamProfile = (props) => {
    const data = this.props.data.getTeamBillingPlan;
    const billingPlan = data || {
      plan: "",
      status: "loading",
      trialDaysLeft: "",
    };

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <TeamProfileContainer
          userId={this.props.user.id}
          billingPlan={billingPlan}
          {...props}
        />
      </ErrorBoundary>
    );
  };

  renderForecastPage = (props) => {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Forecasts {...props} />
      </ErrorBoundary>
    );
  };

  toToday = (props, board) => {
    const params = props.match.params;
    const defaultScreenDate = this.props.user.teams[0].defaultScreenDate
      ? +Moment(this.props.user.teams[0].defaultScreenDate)
      : +new Date();
    if (board === "planner") {
      return <Redirect to={"/b/" + params.boardId + "/" + defaultScreenDate} />;
    } else if (board === "member") {
      return <Redirect to={"/myschedule/" + defaultScreenDate} />;
    }
  };

  render() {
    return (
      <Suspense
        fallback={
          <div
            {...css({
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "2",
            })}
          >
            <img
              src={logoSoon}
              width="140px"
              alt="Loading Soon"
              {...css({ animation: `${beat} 1s infinite alternate` })}
            />
          </div>
        }
      >
        <Switch>
          {/* <Route exact path="/" component={ActivityDetails} /> */}
          <Route exact path="/" render={this.roleCheck} />

          <Route path="/boards" render={this.renderBoards} />
          <Route
            path="/dashboard"
            render={() => {
              return <Redirect to="/boards" />;
            }}
          />
          <Route path="/myschedule/:date" render={this.renderSchedule} />
          <Route
            path="/myschedule/"
            render={(props) => this.toToday(props, "member")}
          />
          <Route path="/board/:boardId/:boardUrl/" render={this.renderBoard} />
          <Route path="/b/:boardId/" render={this.renderBoard} />
          <Route path="/account/:teamId" render={this.renderAccount} />
          <Route path="/users/:teamId" render={this.renderUsers} />
          <Route path="/leave/:tab/:date/:leaveId" render={this.renderLeave} />
          <Route path="/leave/:tab/" render={this.renderLeave} />
          <Route path="/profile/:teamId" render={this.renderTeamProfile} />
          <Route path="/user/:userId" render={this.renderUser} />
          <Route path="/forecasts" render={this.renderForecastPage} />
          <Route path="/verifyemail/:token" component={VerifyEmail} />
          <Route component={PageNotFound} />
        </Switch>
        {this.state.timezoneConflict && (
          <TimezoneConflict
            updateTimeZone={() =>
              this.props
                .updateUser({
                  variables: {
                    id: this.props.user.id,
                    timezone: Moment.tz.guess(),
                  },
                })
                .then(() => {
                  this.setState({ timezoneChanged: true }, () => {
                    setTimeout(() => {
                      this.setState({ timezoneConflict: false });
                    }, 5000);
                  });
                })
            }
            timezoneChanged={this.state.timezoneChanged}
            closeModal={() => this.setState({ timezoneConflict: false })}
          />
        )}
      </Suspense>
    );
  }
}

const GET_TEAM_BILLING_PLAN_QUERY = gql`
  query {
    getTeamBillingPlan
  }
`;

const updateUser = gql`
  mutation ($id: ID!, $timezone: String) {
    updateUser(id: $id, timezone: $timezone) {
      id
      timezone
    }
  }
`;

export default compose(
  graphql(GET_TEAM_BILLING_PLAN_QUERY, {
    options: () => ({
      fetchPolicy: "network-only",
    }),
  }),
  graphql(updateUser, { name: "updateUser" })
)(Router);

function AnnouncementModal({
  icon,
  iconStyle,
  title,
  body,
  buttonOne,
  buttonTwo,
  customStyle,
  customLayerStyle,
}) {
  return (
    <div
      {...css(
        {
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.2)",
          zIndex: 2,
        },
        customLayerStyle
      )}
    >
      <div
        {...css(
          {
            position: "absolute",
            width: "calc(100% - 32px)",
            maxWidth: 360,
            height: "fit-content",
            marginRight: 16,
            marginLeft: 16,
            background: "white",
            zIndex: 100,
            borderRadius: 5,
            margin: "auto",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
            padding: 36,
            boxSizing: "border-box",
          },
          customStyle,
          $(" a", { color: "rgba(0,0,0,0.54)" }),
          $(
            " p",
            {
              color: "rgba(0,0,0,0.54)",
              fontWeight: 300,
              fontSize: 14,
              padding: 0,
              margin: 0,
              marginBottom: 24,
              wordBreak: "break-word",
            },
            $(":last-child", {
              marginBottom: 0,
            })
          )
        )}
      >
        <h1
          style={{
            fontSize: 20,
            lineHeight: "24px",
            padding: 0,
            margin: 0,
            fontWeight: 500,
            marginBottom: 36,
          }}
        >
          {icon && (
            <span
              className={icon}
              style={{ color: "#28AAFF", fontSize: 24, ...iconStyle }}
            />
          )}{" "}
          {title}
        </h1>
        <div>{body}</div>
        {buttonOne && (
          <button
            className="portal-button"
            {...css(
              {
                color: buttonOne.color ? buttonOne.color : colors.red,
                borderColor: buttonOne.color ? buttonOne.color : colors.red,
                cursor: "pointer",
              },
              $(":hover", {
                background: buttonOne.color ? buttonOne.color : colors.red,
                color: "white",
              }),
              $(":focus", {
                outline: "none",
              })
            )}
            onClick={buttonOne.action}
          >
            {buttonOne.title}
          </button>
        )}
        {buttonTwo && (
          <button
            className="portal-button"
            {...css(
              {
                marginTop: 12,
                color: colors.blue,
                borderColor: colors.blue,
                cursor: "pointer",
              },
              $(":hover", {
                background: colors.blue,
                color: "white",
              }),
              $(":focus", {
                outline: "none",
              }),
              $(":disabled", {
                opacity: 0.5,
                cursor: "default",
                background: colors.blue,
                color: "white",
              })
            )}
            onClick={buttonTwo.action}
            disabled={buttonTwo.disabled}
          >
            {buttonTwo.title}
          </button>
        )}
      </div>
    </div>
  );
}

const TimezoneConflict = ({ updateTimeZone, closeModal, timezoneChanged }) => {
  const currentTimezone = Moment.tz.guess();
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{
          duration: 0.5,
          ease: [0, 1, 0.5, 1],
        }}
        {...css({
          position: "absolute",
          left: 12,
          bottom: 12,
          width: 280,
          height: timezoneChanged ? 156 : 198,
          background: "white",
          borderRadius: 5,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          zIndex: 1,
        })}
      >
        <div
          {...css({
            float: "left",
            width: "100%",
            boxSizing: "border-box",
            padding: "10px 24px 10px 24px",
          })}
        >
          <div
            {...css({
              height: 28,
              paddingBottom: 10,
              borderBottom: "2px solid",
              borderColor: timezoneChanged ? colors.green : colors.orange,
              lineHeight: "28px",
              width: "fit-content",
              fontSize: 16,
              color: timezoneChanged ? colors.green : colors.orange,
            })}
          >
            {timezoneChanged ? "Time zone updated" : "Update time zone?"}
          </div>
          <div
            {...css({
              float: "left",
              width: "100%",
              marginTop: 12,
              fontSize: 14,
              lineHeight: "16px",
              fontWeight: 300,
            })}
          >
            {timezoneChanged ? (
              <span>
                Your display time zone is now set to{" "}
                <strong>{currentTimezone}</strong>.
                <br />
                <br />
                You can always change your display time zone setting in your
                account.
              </span>
            ) : (
              <span>
                Looks like you're in the <strong>{currentTimezone}</strong> time
                zone. <br />
                <br />
                Would you like to update your display time zone setting?
              </span>
            )}
          </div>
        </div>
        {!timezoneChanged && (
          <div
            {...css({
              float: "left",
              width: "100%",
              boxSizing: "border-box",
              borderTop: "1px solid #E2E2E2",
              fontSize: 14,
            })}
          >
            <div
              {...css(
                {
                  float: "left",
                  lineHeight: "42px",
                  paddingLeft: 24,
                  paddingRight: 24,
                  color: colors.te,
                },
                $(":hover", {
                  textDecoration: "underline",
                })
              )}
            >
              <span {...css({ cursor: "pointer" })} onClick={closeModal}>
                DISMISS
              </span>
            </div>
            <div
              {...css(
                {
                  float: "left",
                  lineHeight: "42px",
                  textAlign: "center",
                  cursor: "pointer",
                },
                $(":hover", {
                  textDecoration: "underline",
                })
              )}
            >
              <span onClick={updateTimeZone} {...css({ cursor: "pointer" })}>
                UPDATE TIME ZONE
              </span>
            </div>
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

function NoAccessCheck({ billingPlan, isOwner, history, owner }) {
  if (!billingPlan) return null;
  if (billingPlan.status === "loading") return null;
  const trialDaysLeft = billingPlan.trialDaysLeft;

  let noAccess = false;
  let title;
  if (
    trialDaysLeft === 0 ||
    billingPlan.status === "trial_ended" ||
    !billingPlan.plan
  ) {
    noAccess = true;
    title = "Free trial ended";
  }
  if (billingPlan.status === "subscription_ended") {
    noAccess = true;
    title = "Subscription ended";
  }

  let noAccessPlan = "";
  if (billingPlan.provider === "stripe") {
    noAccessPlan = trialDaysLeft === 0 ? "30-day free trial" : "subscription";
  } else {
    noAccessPlan =
      billingPlan.status === "subscription_ended" ? "subscription" : "trial";
  }

  if (noAccess) {
    return (
      <AnnouncementModal
        icon="bi_user-lock"
        iconStyle={{ color: colors.red }}
        title={title}
        body={
          <div
            {...css(
              $(" p", {
                color: "rgba(0,0,0,0.87) !important",
              })
            )}
          >
            <p>
              Your team’s {noAccessPlan} has ended. We hope you enjoyed using
              Soon!
            </p>
            {isOwner ? (
              <p>
                To unlock your account and continue using Soon, we invite you to
                upgrade your plan.
              </p>
            ) : (
              <p>
                To unlock your account and continue using Soon, reach out to{" "}
                {owner.name} to upgrade the team plan.
              </p>
            )}
            <p>
              Need help? Ask us a question via the menu or email us at{" "}
              <a href="mailto:support@soonapp.io">support@soonapp.io</a>.
            </p>
          </div>
        }
        buttonOne={
          isOwner
            ? {
                title: "Upgrade now",
                action: () => history.push(`/account/billing/plans`),
                color: colors.blue,
              }
            : null
        }
        customStyle={{ top: -60 }}
        customLayerStyle={{ top: 60, zIndex: 3 }}
      />
    );
  } else {
    return null;
  }
}
