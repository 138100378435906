export const REMOVABLE_STATUSES = [
  "CANCELLED",
  "DECLINED_CONFIRMED",
  "COVER_DECLINED",
  "DECLINED",
  "COVERED",
];

export const APP_VERSION = "V6.4.2";

export const LAUNCH_DATES = {
  autoScheduling: "2024-02-08",
  templateGenerator: "2024-09-18",
};
