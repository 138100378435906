import { Route, Switch } from "react-router-dom";
import Datasets from "./Datasets";
import Overview from "./Overview";

import Header from "../header/Header";
import TabMenu from "../toolBox/TabMenu";

export default function Forecasts() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header
        headerTitle="Forecasts"
        userId={""}
        match={{}}
        history={{}}
        billingPlan={""}
      />
      <TabMenu
        customStyle={{ paddingLeft: 24 }}
        items={[
          {
            name: "Forecasts",
            link: `/forecasts/overview`,
          },
          {
            name: "Datasets",
            link: `/forecasts/datasets`,
          },
        ]}
      />
      <Switch>
        <Route path="/forecasts/datasets" component={Datasets} />
        <Route path="/forecasts/overview" component={Overview} />
      </Switch>
    </div>
  );
}
