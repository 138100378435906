/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import React, { Suspense, lazy, useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import "./header.css";
import BoardSwitcher from "./BoardSwitcher";
import HeaderSwitcher from "./HeaderSwitcher";
import Notifications from "../notifications/Notifications";
import retry from "../../helpers/retryLazyLoading";
import { colors } from "../../helpers/styles";
import AlertModal from "../toolBox/AlertModal";
import { connectGoogle, connectOutlook } from "../../helpers/apiConnections";
import { NotificationSettingsModal } from "../notifications/NotificationSettings";
import OutsideAlerter from "../clickOutside/OutsideAlerter";
import Button from "../toolBox/Button";
import toast from "react-hot-toast";

const NavPanel = lazy(() => retry(() => import("../navigationPanel/NavPanel")));
const ProfileContainer = lazy(() =>
  retry(() => import("../profilePanel/ProfileContainer"))
);

function Icon({
  className,
  icon,
  iconStyle,
  image,
  letters,
  label,
  left,
  arrowLeft,
  action,
  intercomTarget,
}) {
  return (
    <div
      className={"header-icon" + " " + className}
      style={{ marginLeft: 12, marginTop: 12, fontSize: icon ? 19 : 16 }}
    >
      <div
        className="tooltip"
        css={css`
          float: left;
          width: 100%;
          height: 100%;
        `}
        data-intercom-target={intercomTarget}
      >
        <div
          css={css`
            float: left;
            width: 100%;
            height: 100%;
            cursor: pointer;
          `}
          style={iconStyle}
          onClick={action ? action : () => {}}
        >
          {icon && <span className={icon} />}
          {image && (
            <img
              style={{ marginLeft: -1, marginTop: -1, borderRadius: 5 }}
              src={image}
              width={36}
              height={36}
            />
          )}
          {letters && !image && (
            <span style={{ fontWeight: 900 }}>
              {letters.one}
              {letters.two}
            </span>
          )}
          {image && <div className="header-icon-layer" />}
        </div>
        {label && (
          <span
            className="tooltiptext"
            css={css`
              left: ${left}px !important;
              top: 52px !important;
              height: 29px;
              line-height: 29px;
              box-sizing: border-box;
              padding-top: 0px !important;
              padding-bottom: 0px !important;
              cursor: default;
              ::after {
                left: ${arrowLeft}px !important;
              }
            `}
          >
            {label}
          </span>
        )}
      </div>
    </div>
  );
}

export default function HeaderBar({
  boardId,
  boardName,
  boards,
  defaultScreenDate,
  format12h,
  googleCalendarConfig,
  googleStatus,
  headerTitle,
  history,
  billingPlan,
  match,
  notificationCount,
  team,
  user,
  isZoomedIn,
  loading,
  handleSaveClose,
  customItems,
  currentCustomItem,
  integrationStatuses,
}) {
  const [profilePanel, setProfilePanel] = useState(false);
  const [navigationPanel, setNavigationPanel] = useState(false);
  const [notificationPanel, setNotificationPanel] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState(false);
  const [dismissedModals, setDismissedModals] = useState([]);

  const plan = billingPlan?.plan;
  const isEnterprise = plan === "enterprise";

  const isMSTeams = import.meta.env.VITE_APP_ISMSTEAMSDOMAIN;

  const integrations = integrationStatuses
    ? integrationStatuses.integrations
    : [];

  const reauthorizationModals = integrations
    .filter(
      (i) =>
        ["CONNECTION_LOST", "NOT_ENOUGH_SCOPES"].includes(i.status) &&
        !dismissedModals.includes(i.id)
    )
    .map((i) => {
      let name;
      let account;
      let action;
      if (i.type === "OUTLOOK_CALENDAR") {
        name = "Outlook Calendar";
        account = "Outlook";
        action = () => connectOutlook({ userId: user.id });
      }
      if (i.type === "GOOGLE_CALENDAR") {
        name = "Google Calendar";
        account = "Google";
        action = async () => {
          await connectGoogle({
            userId: user.id,
            setLoading: () => {},
            callback: null,
          });
        };
      }

      if (isMSTeams) {
        action = null;
      }

      const dismiss = () => setDismissedModals([...dismissedModals, i.id]);
      return (
        <AlertModal
          key={i.id}
          title={
            i.status === "NOT_ENOUGH_SCOPES"
              ? "Insufficient permissions"
              : "Re-authorization required"
          }
          body={
            <span>
              To continue using the <b>{name}</b> integration, please
              re-authorize the connection by signing in with your {account}{" "}
              account.
            </span>
          }
          actionOne={{
            text: "DISMISS",
            action: dismiss,
          }}
          actionTwo={{
            text: isMSTeams ? (
              <a
                href={`${isMSTeams}/boards?integrations#${
                  i.type === "GOOGLE_CALENDAR" ? "google" : "outlook"
                }`}
                target="_blank"
              >
                SIGN IN <span className="bi_interface-out-link" />
              </a>
            ) : (
              "SIGN IN"
            ),
            action,
          }}
          isWarning
        />
      );
    });

  const teamLetters = { one: team.name && team.name[0] };

  const userLetters = {
    one: user.firstName && user.firstName[0],
    two: user.lastName && user.lastName[0],
  };

  let showPopUpMenu = false;
  const isTrial = billingPlan?.status === "trialing";
  const isTeamOwner = user.userIs === "SUPERADMIN";
  const isStillValid = new Date() < new Date("2024-12-06 00:00:00");
  if (isTrial && isTeamOwner && isStillValid) {
    showPopUpMenu = true;
  }

  return (
    <div className="header_header noselect">
      <Icon
        icon="bi_interface-hamburger"
        label="Menu"
        left={75}
        arrowLeft={15}
        action={() => setNavigationPanel(true)}
        intercomTarget="menu"
      />
      <Icon
        className="header_team_icon"
        image={team.logo}
        letters={teamLetters}
        left={68}
        arrowLeft={25}
        label="Boards"
        action={() => history.push("/boards")}
        intercomTarget="boards"
      />
      {customItems && (
        <HeaderSwitcher items={customItems} currentItem={currentCustomItem} />
      )}
      {match && boardId && !loading ? (
        <BoardSwitcher
          boards={boards}
          boardId={boardId}
          boardName={boardName ? boardName : "Untitled"}
          match={match}
          customItems={customItems}
        />
      ) : (
        <div className="header_team_dashboard">{headerTitle}</div>
      )}
      <div className="header_right">
        {showPopUpMenu && <PopUpMenu />}
        {!isZoomedIn && billingPlan && !loading ? (
          <BillingLabel
            billingPlan={billingPlan}
            userIs={user.userIs}
            history={history}
          />
        ) : (
          ""
        )}
        {!isZoomedIn && (
          <div
            style={{
              float: "left",
              position: "relative",
              fontSize: "24px",
              height: "60px",
              lineHeight: "60px",
            }}
          >
            <Icon
              icon="bi_com-bell-a"
              action={() => setNotificationPanel(true)}
              label="Notifications"
              left={46}
              arrowLeft={43}
              intercomTarget="notifications"
            />

            {notificationCount > 0 && (
              <div
                onClick={() => setNotificationPanel(true)}
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "0",
                  background: "#FF3246",
                  fontSize: "12px",
                  lineHeight: "18px",
                  textAlign: "center",
                  width: "18px",
                  height: "18px",
                  borderRadius: "18px",
                  cursor: "pointer",
                }}
              >
                {notificationCount}
              </div>
            )}

            {notificationPanel && (
              <OutsideAlerter action={() => setNotificationPanel(false)}>
                <Notifications
                  teamId={team.id}
                  userId={user.id}
                  handleFocus={() => {
                    setNotificationPanel(false);
                  }}
                  match={match}
                  handleOpenNotificationSettings={() => {
                    setNotificationPanel(false);
                    setNotificationSettings(true);
                  }}
                  exclude="notificationPanel"
                  format12h={format12h}
                />
              </OutsideAlerter>
            )}

            {notificationSettings && (
              <NotificationSettingsModal
                userId={user.id}
                emailNotifications={user.emailNotifications}
                handleClose={() => setNotificationSettings(false)}
              />
            )}
          </div>
        )}
        {handleSaveClose ? (
          <div
            className="header_profile"
            aria-label="header-profile-panel-menu"
            onClick={handleSaveClose}
          >
            <Icon
              image={""}
              letters={""}
              icon="bi_interface-cross"
              iconStyle={{ marginLeft: 2 }}
              label="Close"
              left={61}
              arrowLeft={31}
            />
          </div>
        ) : (
          <div
            className="header_profile"
            aria-label="header-profile-panel-menu"
          >
            <Icon
              image={user.avatar}
              letters={userLetters}
              label="Profile"
              left={61}
              arrowLeft={31}
              action={() => setProfilePanel(true)}
              intercomTarget="profile"
            />
          </div>
        )}
      </div>
      <Suspense fallback={<div />}>
        {navigationPanel && (
          <NavPanel
            handleClose={() => setNavigationPanel(false)}
            team={team}
            match={match}
            userRole={user.userIs}
            defaultScreenDate={defaultScreenDate}
            isEnterprise={isEnterprise}
          />
        )}
        {profilePanel && (
          <ProfileContainer
            handleClose={() => {
              setProfilePanel(false);
              const search = window?.location?.search;
              const hash = window?.location?.hash;
              if (search && hash) {
                window.location.replace(
                  window.location.origin + window.location.pathname
                );
              }
            }}
            userId={user.id}
            history={history}
            googleStatus={googleStatus}
            googleCalendarConfig={googleCalendarConfig}
            integrationStatuses={integrationStatuses}
            teamSettings={team.settings}
          />
        )}
        {/* {reauthorizationRequired && (
          <AlertModal
            title="Re-authorization required"
            body={
              <span>
                To continue using the <b>Google Calendar</b> integration, please
                re-authorize the connection by signing in with your Google
                account.
              </span>
            }
            actionOne={{
              text: "DISMISS",
              action: () => setReauthorizationModal(false),
            }}
            actionTwo={{
              text: "SIGN IN",
              action: async () => {
                await connectGoogle({
                  userId: user.id,
                  setLoading: () => {},
                  callback: () => {},
                });
              },
            }}
            isWarning
          />
        )} */}
        {reauthorizationModals}
      </Suspense>
    </div>
  );
}

function BillingLabel({ history, billingPlan, userIs }) {
  let isTrialing = false;
  let trialEnded = false;
  let subscriptionEnded = false;
  let paymentFailed = false;
  let hasRecurringBilling = true;

  if (billingPlan) {
    if (billingPlan.status === "trialing") {
      isTrialing = true;
    }
    if (billingPlan.status === "subscription_ended") {
      subscriptionEnded = true;
    }
    if (billingPlan.status === "payment_failed") {
      paymentFailed = true;
    }
    if (
      billingPlan.status === "trial_ended" ||
      billingPlan?.trialDaysLeft === 0
    ) {
      trialEnded = true;
    }
    if (
      billingPlan.hasRecurringBilling === false &&
      billingPlan.provider === "azure"
    ) {
      hasRecurringBilling = false;
    }
  }
  const trialDays = billingPlan?.trialDaysLeft;

  const hasLabel =
    isTrialing || trialEnded || subscriptionEnded || paymentFailed;

  const hasSubscription = billingPlan.plan && billingPlan.status === "active";

  /*
  provider: stripe or azure

  stripe: 
    - trialing (trial days left)
    - trial_ended
    - active
    - subscription_ended
    - payment_failed

  azure:
    - trialing (trial days left)
    - active
    - subscription_ended
    - payment_failed
    - no_recurring_billing

  */

  return (
    <div
      className="header_info_bar"
      style={{ display: userIs === "MEMBER" && "none" }}
    >
      {userIs !== "MEMBER" && hasLabel && (
        <div
          css={css`
            height: 36px;
            background: ${subscriptionEnded || paymentFailed || trialEnded
              ? colors.red
              : colors.blue};
            line-height: 36px;
            color: white;
            border-radius: 18px;
            float: left;
            padding-left: 12px;
            padding-right: 12px;
            margin-top: 12px;
            cursor: ${userIs === "SUPERADMIN" ? "pointer" : "default"};
          `}
          onClick={() => {
            if (userIs === "SUPERADMIN") {
              history.push("/account/billing");
            }
          }}
        >
          {hasSubscription ? (
            ""
          ) : subscriptionEnded ? (
            <div
              css={css`
                float: left;
              `}
            >
              Subscription ended{" "}
            </div>
          ) : paymentFailed ? (
            <div
              css={css`
                float: left;
              `}
            >
              Payment failed{" "}
            </div>
          ) : (
            <div
              css={css`
                float: left;
              `}
            >
              {isTrialing ? (
                <div
                  css={css`
                    height: 20px;
                    width: 20px;
                    background: white;
                    border-radius: 20px;
                    color: rgba(0, 0, 0, 0.87);
                    float: left;
                    margin-right: 6px;
                    margin-top: 8px;
                    font-size: 12px;
                    text-align: center;
                    line-height: 20px;
                  `}
                >
                  {trialDays}
                </div>
              ) : (
                ""
              )}
              <div
                css={css`
                  float: left;
                `}
              >
                {isTrialing ? (
                  <span>Trial day{trialDays === 1 ? "" : "s"} left</span>
                ) : (
                  "Free trial ended"
                )}
              </div>
            </div>
          )}
          {userIs === "SUPERADMIN" && (
            <BillingLabelRightPart
              hasSubscription={hasSubscription}
              paymentFailed={paymentFailed}
              subscriptionEnded={subscriptionEnded}
              hasRecurringBilling={hasRecurringBilling}
              provider={billingPlan.provider}
            />
          )}
        </div>
      )}
    </div>
  );
}

function BillingLabelRightPart({
  hasSubscription,
  paymentFailed,
  subscriptionEnded,
  hasRecurringBilling,
  provider,
}) {
  let text = "";
  if (provider === "azure") {
    // either Enable recurring billing or nothing
    // or action required
    if (paymentFailed) {
      text = "Action required";
    } else if (subscriptionEnded) {
      text = "Upgrade";
    } else if (!hasRecurringBilling) {
      text = "Enable recurring billing";
    }
  } else {
    if (paymentFailed) {
      text = "Action required";
    } else if (subscriptionEnded) {
      text = "Upgrade";
    } else {
      text = "Upgrade";
    }
  }

  return (
    <div
      css={css`
        float: left;
        margin-left: 6px;
      `}
    >
      {text && <span style={{ color: "#E2E2E2" }}> | </span>}
      {/* {paymentFailed
        ? "Action required"
        : subscriptionEnded
        ? "Upgrade"
        : !hasRecurringBilling
        ? "Enable recurring billing"
        : "Upgrade"} */}
      {text}
    </div>
  );
}

function PopUpMenu() {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div
      css={css`
        float: left;
        position: relative;
        margin-right: 12px;
        @media (max-width: 1023px) {
          margin-right: 0px;
        }
      `}
    >
      <div
        css={css`
          color: white;
          padding: 6px 12px;
          cursor: pointer;
          border-radius: 18px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          height: 36px;
          box-sizing: border-box;
          margin-top: 12px;
          line-height: 24px;
          font-size: 14px;
          :hover {
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid transparent;
          }
          @media (max-width: 610px) {
            display: none;
          }
        `}
        onClick={() => setShowMenu(!showMenu)}
      >
        🎁 50% Off
      </div>
      {showMenu && (
        <OutsideAlerter action={() => setShowMenu(false)}>
          <div
            css={css`
              position: absolute;
              top: 50px;
              right: 0px;
              background: white;
              border-radius: 5px;
              padding: 24px;
              color: black;
              width: 343px;
              box-sizing: border-box;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
              font-size: 14px;
              font-weight: 300;
              display: flex;
              flex-direction: column;
              gap: 12px;
              line-height: 20.5px;
              z-index: 1000;
            `}
          >
            <div
              css={css`
                font-size: 16px;
              `}
            >
              <strong>🎁 Black Friday & Cyber Monday Deal!</strong>
            </div>
            <div>
              Celebrate and <strong>save 50%</strong> on your first Team or
              Business plan!
              <ul
                css={css`
                  margin-top: 6px;
                  padding-left: 24px;
                  margin-bottom: 24px;
                `}
              >
                <li>Applies to both monthly and yearly plans.</li>
                <li>One-time only, valid until Dec 5, 2024.</li>
              </ul>
              🔑 Coupon Code: <b>SOONBF2024</b>{" "}
              <span
                css={css`
                  cursor: pointer;
                  color: ${colors.blue};
                  font-weight: 500;
                  text-decoration: underline;
                  :hover {
                    text-decoration: none;
                  }
                `}
                onClick={() => {
                  navigator.clipboard.writeText("SOONBF2024");
                  toast.success("Copied to clipboard");
                }}
              >
                Copy code
              </span>
              <br />
              <br />
              Use the coupon code code at checkout to claim this exclusive
              offer.
            </div>
            <div>
              <Button
                name="Upgrade now!"
                theme="blue"
                action={() => {
                  // redirect to billing page
                  window.location.href = "/account/billing";
                }}
                size="medium"
                customStyle={{ width: "100%" }}
              />
            </div>
          </div>
        </OutsideAlerter>
      )}
    </div>
  );
}
