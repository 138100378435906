import { useState } from "react";
import { colors } from "../../helpers/styles";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";
import Moment from "moment-timezone";
import { gql, useQuery } from "@apollo/client";
import {
  crossHair,
  drawRectanglePlugin,
  tooltip,
} from "../toolBox/chart/Plugins";

ChartJS.defaults.font.family = "Museo Sans"; // Set default font family
ChartJS.defaults.font.size = 14; // Set default font size
ChartJS.defaults.color = "rgba(0,0,0,0.87)";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

ChartJS.register(drawRectanglePlugin);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: "time",
      position: "bottom",
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        // stepSize: 1,
        padding: 6,
        color: "rgba(0,0,0,0.54)",
        font: {
          size: 12,
          weight: 300,
        },
      },
    },
    y: {
      beginAtZero: false,
      grid: {
        color: "#eee",
      },
      border: {
        display: false,
      },
      ticks: {
        padding: 6,
        color: "rgba(0,0,0,0.54)",
        font: {
          size: 12,
          weight: 300,
        },
      },
    },
  },
  interaction: {
    intersect: false,
    mode: "x",
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: tooltip,
  },
  onHover: crossHair,
};

export default function DailyChart() {
  const [startDate, setStartDate] = useState(
    Moment("2024-10-01").startOf("day").toISOString()
  );
  const [endDate, setEndDate] = useState(
    Moment("2024-10-01").endOf("day").toISOString()
  );
  const [granularity, setGranularity] = useState("day");
  const response = useQuery(GET_FORECASTS, {
    variables: {
      startDate: startDate,
      endDate: endDate,
      comparisonStartDate: Moment(startDate).subtract(1, "month").toISOString(),
      comparisonEndDate: Moment(endDate).subtract(1, "month").toISOString(),
    },
  });

  const [linesShown, setLinesShown] = useState<string[]>([
    "forecast",
    "actual",
  ]);

  const queriedData = response.data?.getTimeSeries;

  let forecastData = [];
  let comparisonData = [];
  if (queriedData) {
    forecastData = queriedData.forecast_data;
    comparisonData = queriedData.comparison_data;
  }

  if (response.loading) {
    return <div>Loading...</div>;
  }
  if (response.error) {
    return <div>Error: {response.error.message}</div>;
  }

  const timeRange = {
    start: forecastData.length > 0 ? forecastData[0].timestamp : null,
    end:
      forecastData.length > 1
        ? forecastData[forecastData.length - 1].timestamp
        : null,
  };

  function toggleLine(lines: string[]) {
    let newLinesShown = [...linesShown];
    for (const line of lines) {
      if (linesShown.includes(line)) {
        newLinesShown = newLinesShown.filter((l) => l !== line);
      } else {
        newLinesShown = [...newLinesShown, line];
      }
    }
    setLinesShown(newLinesShown);
  }

  const data = {
    datasets: [
      {
        id: "actual",
        label: "Actual",
        data: forecastData.map((item) => {
          return {
            x: Moment(item.timestamp, "YYYY-MM-DD HH:mm:ss").toDate(),
            y: item.actual,
          };
        }),
        borderColor: colors.orange,
        borderWidth: 2,
        pointRadius: 0,
        hidden: !linesShown.includes("actual"),
      },
      {
        id: "forecast",
        label: "Forecast",
        data: forecastData.map((item) => {
          return {
            x: Moment(item.timestamp, "YYYY-MM-DD HH:mm:ss").toDate(),
            y: item.forecast,
          };
        }),
        borderColor: colors.violet,
        borderWidth: 2,
        pointRadius: 0,
        hidden: !linesShown.includes("forecast"),
      },
      {
        id: "forecastUpper",
        label: "Forecast Upper",
        data: forecastData.map((item) => {
          return {
            x: Moment(item.timestamp, "YYYY-MM-DD HH:mm:ss").toDate(),
            y: item.upperBound,
          };
        }),
        borderColor: colors.violet,
        borderDash: [5, 5],
        borderWidth: 0,
        backgroundColor: "rgba(85, 81, 255, 0.10)",
        fill: 3,
        pointRadius: 0,
        hidden: !linesShown.includes("forecastUpper"),
      },
      {
        id: "forecastLower",
        label: "Forecast Lower",
        data: forecastData.map((item) => {
          return {
            x: Moment(item.timestamp, "YYYY-MM-DD HH:mm:ss").toDate(),
            y: item.lowerBound,
          };
        }),
        borderColor: colors.violet,
        borderDash: [5, 5],
        borderWidth: 0,
        pointRadius: 0,
        hidden: !linesShown.includes("forecastLower"),
      },
      {
        id: "previousActual",
        label: "Previous Actual",
        data: comparisonData.map((item) => {
          return {
            x: Moment(item.timestamp, "YYYY-MM-DD HH:mm:ss")
              .add(1, "month")
              .toDate(),
            y: item.actual,
          };
        }),
        borderColor: "grey",
        borderWidth: 2,
        pointRadius: 0,
        hidden: !linesShown.includes("previousActual"),
      },
    ],
  };

  options.scales.x.time = {
    unit:
      granularity === "day" ? "hour" : granularity === "week" ? "day" : "day",
    displayFormats: {
      hour: "HH:mm",
      day: "MMM D",
    },
  };
  // options.plugins.drawRectanglePlugin = {
  //   startTime: timeRange.start,
  //   endTime: timeRange.end,
  //   color: "rgba(0, 123, 255, 0.5)",
  // };
  if (granularity !== "day") {
    // options.scales.x.type = "linear";
    options.scales.x.ticks.callback = function (value, index, values) {
      if (granularity === "week") {
        return [
          Moment(value).format("ddd"),
          Moment(value).format(
            `MMM D ${!Moment(value).isSame(Moment(), "year") ? " YYYY" : ""}`
          ),
        ];
      } else if (granularity === "month") {
        return [Moment(value).format("D"), Moment(value).format("MMM")];
      } else if (granularity === "year") {
        return [Moment(value).format("MMM")];
      }
    };
  }

  return (
    <div
      style={{ padding: 24, display: "flex", flexDirection: "column", gap: 24 }}
    >
      <div style={{ display: "flex", gap: 24 }}>
        <div>
          <button
            onClick={() => {
              if (granularity === "day") {
                const newStartDate = Moment(startDate)
                  .subtract(1, "day")
                  .toISOString();
                const newEndDate = Moment(newStartDate)
                  .endOf("day")
                  .toISOString();

                setStartDate(newStartDate);
                setEndDate(newEndDate);
              } else if (granularity === "week") {
                const newStartDate = Moment(startDate)
                  .subtract(1, "week")
                  .toISOString();
                const newEndDate = Moment(newStartDate)
                  .endOf("week")
                  .toISOString();

                setStartDate(newStartDate);
                setEndDate(newEndDate);
              } else if (granularity === "month") {
                const newStartDate = Moment(startDate)
                  .subtract(1, "month")
                  .toISOString();
                const newEndDate = Moment(newStartDate)
                  .endOf("month")
                  .toISOString();

                setStartDate(newStartDate);
                setEndDate(newEndDate);
              } else if (granularity === "year") {
                const newStartDate = Moment(startDate)
                  .subtract(1, "year")
                  .toISOString();
                const newEndDate = Moment(newStartDate)
                  .endOf("year")
                  .toISOString();

                setStartDate(newStartDate);
                setEndDate(newEndDate);
              }
            }}
          >
            previous
          </button>
          <span>
            {Moment(startDate).format(
              granularity === "day"
                ? "ddd D MMM"
                : granularity === "week"
                  ? "ddd D MMM"
                  : granularity === "month"
                    ? "MMM YYYY"
                    : "YYYY"
            )}
          </span>
          {granularity !== "day" && (
            <span>
              -
              {Moment(endDate).format(
                granularity === "day"
                  ? "ddd D MMM YYYY"
                  : granularity === "week"
                    ? "ddd D MMM YYYY"
                    : granularity === "month"
                      ? "MMM YYYY"
                      : "YYYY"
              )}
            </span>
          )}
          <button
            onClick={() => {
              if (granularity === "day") {
                const newStartDate = Moment(startDate)
                  .add(1, "day")
                  .toISOString();
                const newEndDate = Moment(newStartDate)
                  .endOf("day")
                  .toISOString();

                setStartDate(newStartDate);
                setEndDate(newEndDate);
              } else if (granularity === "week") {
                const newStartDate = Moment(startDate)
                  .add(1, "week")
                  .toISOString();
                const newEndDate = Moment(newStartDate)
                  .endOf("week")
                  .toISOString();

                setStartDate(newStartDate);
                setEndDate(newEndDate);
              } else if (granularity === "month") {
                const newStartDate = Moment(startDate)
                  .add(1, "month")
                  .toISOString();
                const newEndDate = Moment(newStartDate)
                  .endOf("month")
                  .toISOString();

                setStartDate(newStartDate);
                setEndDate(newEndDate);
              } else if (granularity === "year") {
                const newStartDate = Moment(startDate)
                  .add(1, "year")
                  .toISOString();
                const newEndDate = Moment(newStartDate)
                  .endOf("year")
                  .toISOString();

                setStartDate(newStartDate);
                setEndDate(newEndDate);
              }
            }}
          >
            next
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              setGranularity("day");
              const newStartDate = Moment(startDate)
                .startOf("day")
                .toISOString();
              const newEndDate = Moment(newStartDate)
                .endOf("day")
                .toISOString();

              setStartDate(newStartDate);
              setEndDate(newEndDate);
            }}
            disabled={granularity === "day"}
          >
            day
          </button>
          <button
            onClick={() => {
              setGranularity("week");
              const newStartDate = Moment(startDate)
                .startOf("isoWeek")
                .toISOString();
              const newEndDate = Moment(newStartDate)
                .endOf("isoWeek")
                .toISOString();

              setStartDate(newStartDate);
              setEndDate(newEndDate);
            }}
            disabled={granularity === "week"}
          >
            week
          </button>
          <button
            onClick={() => {
              setGranularity("month");
              const newStartDate = Moment(startDate)
                .startOf("month")
                .toISOString();
              const newEndDate = Moment(newStartDate)
                .endOf("month")
                .toISOString();

              setStartDate(newStartDate);
              setEndDate(newEndDate);
            }}
            disabled={granularity === "month"}
          >
            month
          </button>
          <button
            onClick={() => {
              setGranularity("year");
              const newStartDate = Moment(startDate)
                .startOf("year")
                .toISOString();
              const newEndDate = Moment(newStartDate)
                .endOf("year")
                .toISOString();

              setStartDate(newStartDate);
              setEndDate(newEndDate);
            }}
            disabled={granularity === "year"}
          >
            year
          </button>
        </div>
        <div>Compare actuals</div>
      </div>
      <div
        style={{ border: "1px solid #F3F3F3", borderRadius: 5, padding: 24 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 24,
            marginBottom: 10,
          }}
        >
          {data.datasets
            .filter((dataset) => dataset.id !== "forecastLower")
            .map((dataset, index) => {
              let isUpperBound = false;
              if (dataset.id === "forecastUpper") {
                isUpperBound = true;
              }

              let name = dataset.label;
              if (isUpperBound) {
                name = "Forecast Upper & Lower Bound";
              }
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (isUpperBound) {
                      toggleLine(["forecastUpper", "forecastLower"]);
                    } else {
                      toggleLine([dataset.id]);
                    }
                  }} // Toggle visibility
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    opacity: linesShown.includes(dataset.id) ? 1 : 0.5, // Change opacity when hidden
                  }}
                >
                  {/* Color box for legend */}
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: "100%",
                      backgroundColor: dataset.borderColor,
                      marginRight: 12,
                    }}
                  ></div>
                  {/* Legend text */}
                  <span style={{ fontSize: 14, fontWeight: 300 }}>{name}</span>
                </div>
              );
            })}
        </div>
        <div style={{ height: 400, width: "100%" }}>
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

const GET_FORECASTS = gql`
  query getForecasts(
    $startDate: DateTime!
    $endDate: DateTime!
    $comparisonStartDate: DateTime
    $comparisonEndDate: DateTime
  ) {
    getTimeSeries(
      startDate: $startDate
      endDate: $endDate
      comparisonStartDate: $comparisonStartDate
      comparisonEndDate: $comparisonEndDate
    )
  }
`;
