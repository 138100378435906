import React, { useState } from "react";

function FileUpload() {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);

  // Handle file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setUploadStatus("");
    setErrorMessages([]);
  };

  // Upload the file to the Python server
  const uploadFile = async () => {
    if (!file) {
      setUploadStatus("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("csv_file", file);

    try {
      const token = localStorage.getItem("graphcoolToken");
      const authorizationHeader = token ? `Bearer ${token}` : "";
      const headers = new Headers();
      headers.append("Authorization", authorizationHeader);

      const response = await fetch(
        "http://localhost:5000/forecast/data/upload",
        {
          method: "POST",
          body: formData,
          headers: headers,
        }
      );

      const data = await response.json();

      if (response.ok) {
        setUploadStatus("File uploaded successfully!");
        setErrorMessages([]);
      } else {
        setUploadStatus("File upload failed.");
        if (data.errors) {
          setErrorMessages(data.errors);
        }
      }
    } catch (error) {
      setUploadStatus("An error occurred during the upload.");
      setErrorMessages([error.message]);
    }
  };

  return (
    <div>
      <h2>Upload CSV File</h2>

      <input type="file" onChange={handleFileChange} accept=".csv" />

      <button onClick={uploadFile} disabled={!file}>
        Upload
      </button>

      {uploadStatus && <p>{uploadStatus}</p>}

      {errorMessages.length > 0 && (
        <ul>
          {errorMessages.map((error, index) => (
            <li key={index} style={{ color: "red" }}>
              {error}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

function GenerateForecast() {
  const [filePath, setFilePath] = useState("");
  return (
    <div>
      <input
        type="text"
        value={filePath}
        onChange={(e) => setFilePath(e.target.value)}
      />
      <button>Generate</button>
    </div>
  );
}

export default function Datasets() {
  return (
    <div>
      <GenerateForecast />
      <FileUpload />
    </div>
  );
}
