import React from "react";
import { css, select as $ } from "glamor";
import { NavLink } from "react-router-dom";

import { colors } from "../../helpers/styles";

class TabMenu extends React.Component {
  render() {
    const {
      items,
      subItems,
      customStyle,
      customListItemStyle,
      fullWidthItems,
      subStyle,
    } = this.props;
    const list = items
      .filter((i) => i)
      .map((item, i) => {
        const activeColor = item.activeColor ? item.activeColor : colors.blue;
        const inActiveColor = "rgba(0,0,0,0.38)";
        const width = fullWidthItems && 100 / items.length + "%";
        const listItem = (
          <li
            {...css(
              {
                borderBottom: item.active ? "2px solid " + activeColor : "0px",
                lineHeight: "48px",
                boxSizing: "border-box",
                height: 48,
                float: "left",
                fontSize: subStyle ? 14 : 16,
                marginRight: item.link || fullWidthItems ? 0 : 24,
                cursor: "pointer",
                width: fullWidthItems && "100%",
                textAlign: fullWidthItems && "center",
                position: "relative",
              },
              customListItemStyle
            )}
            aria-label={"Click to switch tab"}
            onClick={item.action ? item.action : () => {}}
          >
            {item.name}
            {item.notifications > 0 && (
              <span
                {...css({
                  width: 20,
                  height: 20,
                  borderRadius: 20,
                  background: item.notificationColor
                    ? item.notificationColor
                    : colors.blue,
                  fontSize: 12,
                  color: "white",
                  display: "inline-block",
                  lineHeight: "20px",
                  marginLeft: 2,
                  verticalAlign: 2,
                  textAlign: "center",
                })}
              >
                {item.notifications}
              </span>
            )}
          </li>
        );

        return (
          <div key={i} {...css({ float: "left", width })}>
            {item.link ? (
              <NavLink
                to={item.link}
                activeClassName="navigation-active"
                {...css({
                  float: "left",
                  marginRight: 24,
                  color: inActiveColor,
                })}
              >
                {listItem}
              </NavLink>
            ) : (
              <span
                {...css({
                  float: "left",
                  width: fullWidthItems && "100%",
                  color: item.active ? activeColor : inActiveColor,
                })}
              >
                {listItem}
              </span>
            )}
          </div>
        );
      });
    return (
      <div>
        <ul
          title="Tab Menu List"
          {...css(
            {
              float: "left",
              width: "100%",
              borderBottom: "1px solid #E2E2E2",
              listStyle: "none",
              margin: 0,
              padding: 0,
              height: 48,
              boxSizing: "border-box",
              background: subStyle ? "#F9F9F9" : "white",
              userSelect: "none",
            },
            $(" li", {
              display: "inline",
            }),
            customStyle
          )}
        >
          {list}
        </ul>
      </div>
    );
  }
}

export default TabMenu;
